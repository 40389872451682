@import url("https://fonts.googleapis.com/css2?family=Uncut+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Mega:wght@100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

.card_title {
    bottom: 20%;
    left: 38%;
    transform: translate(-50%, -50%);
}

.card_text {
    bottom: 5%;
    left: 25%;
    transform: translate(-50%, -50%);
}

.text_collector_card_text {
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 40%);
}
